























import Logos from '@/components/ui/Logos/Logos.vue'
import FormSignup from '@/components/forms/SignUp.vue'
import { vrsStoreConfig } from '@/store/config/store'
import { vrsStoreAuth } from '@/store/auth/store'

export default {
  name: 'SignUp',
  components: {
    'ui-logos': Logos,
    FormSignup
  },
  data () {
    return {
      config: vrsStoreConfig.state,
      auth: vrsStoreAuth.state,
      success: false
    }
  },
  computed: {
    logos: vrsStoreConfig.computed.logos
  },
  mounted () {
    vrsStoreAuth.state.error = null
  },
  methods: {
    async handleSubmit (data) {
      await vrsStoreAuth.actions.signUp(data)

      // display a message to explain to the user
      // he has been registered
      if (!vrsStoreAuth.state.error) {
        this.success = true
      }
    }
  }

}
