<template>
  <div class="p-2 pb-12 m-auto" style="max-width: 40rem">
    <div class="w-100 mb-4" v-if="!config.loading">
      <ui-logos :logos="logos" />
      <h1 class="center">{{config.data.SITE_NAME}}</h1>
      <h2 class="center">{{config.data.SITE_DESCRIPTION}}</h2>
    </div>

    <h2>Privacy Policy</h2>

    <p>
      Data collected from people authorised by the FIA in order to create a personal account,
      which will allow them to access the weather forecasting service available on the FIA website.
    </p>

    <p>
      Upon your registration,
      we collect the following data for the purpose of providing weather information to you.
      Your data is not used for any other purposes or shared with third parties.
      It is removed upon your withdrawal of consent or your request to erasure.
    </p>

    <p>
      Last name, First name, Username, password, email address: this data is collected when you register online.
    </p>

    <p>
      IP and browser user agent string: this data is collected when you log in to access the website https://fiaweather.mfsports.fr.
    </p>

    <p>
      Retention period: the aforementioned data is retained during the time that your account is active.
    </p>

    <h2>Data collected for the purpose of processing statistics</h2>

    <p>
      Based on your consent, we collect the following data for the purpose of running this website.
      Your data is not used for any other purposes or shared with third parties.
      It is removed upon your request.
    </p>
    <p>
      The website uses Matomo Analytics,
      a web analytics plateform hosted on our server and allows us to notice trends to improve the user experience on our website.
      This plateform processes personal data such as: the username, the date and time, the title of the page being viewed,
      the URL of the page being viewed, the URL of the page that was viewed prior to the current page, the screen resolution,
      the time in local timezone, the type of device, and the country, region, and city.
    </p>
    <p>
      Personal data collected for statistical purposes will be kept for a period of 13 months.
    </p>
    <p>
      You may opt out of this tracking at any time by activating the “Do Not Track” setting in your browser.
      Your rights pertaining your data
    </p>
    <p>
      If you have registered on the website, you can request to receive an exported file of the personal data we hold about you,
      including any data you have provided to us.
      You can also request that we rectify or erase any personal data we hold about you.
    </p>

    <ul class="list-inside list-disc">
      <li>The right to withdraw consent</li>
      <li>The right of access</li>
      <li>The right to erasure</li>
      <li>The right to rectification</li>
      <li>The right to data portability</li>
      <li>The right to object</li>
      <li>Notification of data breaches</li>
      <li>The right to lodge a complaint with a supervisory authority</li>
    </ul>

    <h2>Changes to our privacy policy</h2>

    <p>
      Meteo-France keeps its privacy policy under regular review and places any updates on this web page.
      This privacy policy was last updated on 7 February 2020.
    </p>

    <h2>How to contact us</h2>

    <p>
      If you have any questions about Meteo-France’s privacy policy,
      the data we hold on you, or you would like to exercise one of your data protection rights,
      please do not hesitate to contact us.
    </p>

    <p>
      Email us at weatherf1@meteo.fr and dpo@meteo.fr with regards to anything that concerns the use of your personal data.
    </p>

    <router-link to="/">Return to home</router-link>
  </div>
</template>
<script>
import Logos from '@/components/ui/Logos/Logos.vue'
import { vrsStoreConfig } from '@/store/config/store'

export default {
  name: 'PrivacyPolicy',
  components: {
    'ui-logos': Logos
  },
  data () {
    return {
      config: vrsStoreConfig.state
    }
  },
  computed: {
    logos: vrsStoreConfig.computed.logos
  }

}
</script>

<style lang="postcss" scoped>

/deep/.Logos img {
  @media (max-width: 767px) {
    height: 7rem;
    max-width: 7rem;
  }
  @media (max-width: 479px) {
    height: 5rem;
    max-width: 5rem;
  }
}

h2, h3, p, ul {
  text-align: unset;
  width: 100%;
  padding: 0.5rem 0 0.25rem 0;
}

</style>
