





















































import Loader from '@/components/ui/Loader/Loader.vue'
import Button from '@/components/ui/Button/Button.vue'

export default {
  name: 'FormSignUp',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object,
      default: () => ({
        message: '',
        response: null
      })
    }
  },
  components: {
    'ui-button': Button,
    'ui-loader': Loader
  },
  data: () => ({
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      username: '',
      password: '',
      confirm_password: '',
      passphrase: '',
      policy: false
    },
    fields: [
      {
        label: 'First name',
        prop: 'first_name',
        attrs: {
          required: true,
          type: 'text',
          placeholder: 'First name'
        }
      },
      {
        label: 'Last name',
        prop: 'last_name',
        attrs: {
          required: true,
          type: 'text',
          placeholder: 'Last name'
        }
      },
      {
        label: 'e-mail',
        prop: 'email',
        attrs: {
          required: true,
          type: 'email',
          placeholder: 'Enter your email'
        }
      },
      {
        label: 'Username',
        prop: 'username',
        attrs: {
          required: true,
          type: 'text',
          placeholder: 'Pick a username'
        }
      },
      {
        label: 'Password',
        prop: 'password',
        attrs: {
          required: true,
          type: 'password',
          placeholder: 'Choose a password'
        },
        hint: '8 characters minimum, 1 uppercase character, 1 special character, 1 digit'
      },
      {
        label: 'Confirm your password',
        prop: 'confirm_password',
        attrs: {
          required: true,
          type: 'password',
          placeholder: 'Choose a password'
        },
        hint: 'Please re enter your password'
      },
      {
        label: 'Passphrase given for your sign up',
        prop: 'passphrase',
        attrs: {
          required: true,
          type: 'text',
          placeholder: 'Passphrase received by mail'
        }
      },
      {
        label: 'Privacy policy',
        prop: 'policy',
        attrs: {
          required: true,
          type: 'checkbox',
          placeholder: 'I agree with the collect'
        },
        hint: 'I agree with the collect and the privacy policy'
      }
    ]
  }),
  methods: {
    handleSubmit () {
      if (this.formData.policy) {
        this.$emit('submit', this.formData)
      }
    }
  }
}
